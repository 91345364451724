import { UI_DATE_FORMAT_WITH_TIME_24 } from '@/config/constants/static';
import { getDateLocale } from '@/utils/utils';
import { Field } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';


const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));

const RenderComponent = ({
  values,
  handleEdit,
  validateForm,
  customProps,
  submitAction,
  cancelAction,
}) => {
  const { i18n } = useTranslation();
  return (
    <>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <Label variant="body1" htmlFor="eventType">
          {i18n.t('camera.event_type')}
        </Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="eventType"
            onChange={(event) => handleEdit(event, { field: 'eventType' })}
            as={Select}
            isMulti
            placeholder={i18n.t('common.pleaseSelect')}
            multiSelect
            value={values['eventType']}
            suggestions={customProps.fleetCompanyList}
          />
          <Message type="error" message={customProps.validationError} />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <Label variant="body1" htmlFor="vehicleId">
          {i18n.t('camera.vehicles')}
        </Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="vehicleId"
            onChange={(event) => handleEdit(event, { field: 'vehicleId' })}
            as={Select}
            placeholder={i18n.t('common.pleaseSelect')}
            value={values['vehicleId']}
            suggestions={customProps.vehicleList}
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <FormStyle.default.TextWrapper>
          <Label variant="body1" htmlFor="startDate">
            {i18n.t('camera.dateTimeFrom')}
          </Label>
          <Field
                name="startDate"
                onChange={(event) => {
                  handleEdit(moment(event).format(UI_DATE_FORMAT_WITH_TIME_24), {
                    field: 'startDate',
                    type: 'date',
                    isTimeNeeded: true
                  });
                }}
                as={ReactDatePicker}
                selected={values['startDate'] ? moment(values['startDate'], UI_DATE_FORMAT_WITH_TIME_24).toDate() : null}
                locale={getDateLocale()}
                placeholder={i18n.t('common.datePicker')}
                showPopperArrow={false}
                showTimeSelect={true}
                maxDate={new Date(values['endDate']) || new Date()}
                showDisabledMonthNavigation
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat={UI_DATE_FORMAT_WITH_TIME_24}
              />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <FormStyle.default.TextWrapper>
          <Label variant="body1" htmlFor="eventType">
            {i18n.t('camera.dateTimeTo')}
          </Label>
          <Field
            name="endDate"
            onChange={(event) => {
              console.log("sasdasads", event)
              handleEdit(moment(event).format(UI_DATE_FORMAT_WITH_TIME_24), {
                field: 'endDate',
                type: 'date',
                isTimeNeeded: true
              });
            }}
            as={ReactDatePicker}
            locale={getDateLocale()}
            selected={values['endDate'] ? moment(values['endDate'], UI_DATE_FORMAT_WITH_TIME_24).toDate() : null}
            placeholder={i18n.t('common.datePicker')}
            showPopperArrow={false}
            showTimeSelect={true}
            minDate={new Date(values['startDate']) || null}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat={UI_DATE_FORMAT_WITH_TIME_24}
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.ButtonsWrapper className="vertical-button">
        <Button
          type="submit"
          label={i18n.t('common.view')}
          onClick={(e, d) => {
            const validateResponse = validateForm();
            validateResponse.then((data) => {
              submitAction(data);
            });
          }}
        />
        <Button
          className=""
          type={'reset'}
          label={i18n.t('common.clear')}
          onClick={() => {
            cancelAction();
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  hasApiStatus: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
