import useTheme from '@material-ui/core/styles/useTheme';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { colors } from '../../../../config/theme/variables';

const layout = window?.$environment?.CURRENT_LAYOUT;
const CheckBox = React.lazy(() => import(`@/components/UI/Toggle/${layout}`));

const { forwardRef, useImperativeHandle } = React;

const SelectFn = forwardRef((props, ref) => {
  const i18n = useTranslation();
  const { Styled, searchWithStart } = props;
  const theme = useTheme();

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: colors.PRIMARY_MAIN,
      width: '100%',
      border: `2px solid ${colors.LIGHT_BORDER}`,
      cursor: 'pointer',
      ...(props?.multiSelect ? { height: '30px' } : {}),
      borderRadius: '0px',
      ':hover': {
        border: `2px solid ${colors.LIGHT_BORDER}`
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        borderRadius: '0px',
        color: isFocused ? 'colors.WHITE' : 'colors.PRIMARY_CONTRAST',
        cursor: 'pointer',
        backgroundColor:
          !isDisabled && isFocused
            ? theme.palette.secondary.main
            : isSelected
            ? theme.PRIMARY_MAIN
            : theme.PRIMARY_MAIN,
        ':hover': {
          backgroundColor: theme.palette.secondary.main,
          color: 'colors.WHITE'
        },
        width: '100%',
        overflow: 'hidden',
        fontFamily: theme.palette.fontFamily
      };
    },
    multiValue: (styles, { data }) => {
      const color = colors.PRIMARY_MAIN;
      return {
        ...styles,
        backgroundColor: color,
        cursor: 'pointer',
        border: '1px solid #817777',
      };
    },
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        color: 'colors.PRIMARY_CONTRAST',
        cursor: 'pointer',
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: 'colors.WHITE',
      cursor: 'pointer'
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: 'colors.WHITE',

      ':hover': {
        backgroundColor: colors.PRIMARY_MAIN,
        color: 'colors.WHITE'
      },
      cursor: 'pointer'
    })
  };
  useImperativeHandle(ref, () => ({
    clearValue() {
      setTimeout(() => {
        setSingle(null);
      }, 100);
    }
  }));
  const [single, setSingle] = React.useState(null);

  useEffect(() => {
    if (props.setNullForChat && props.setNullForChat === 'clear') {
      setSingle(null);
    }
  }, [props.setNullForChat, single]);
  const handleChangeSingle = (value, event) => {
    var result = [];
    if (value && value.length > 0) {
      if (value[value.length - 1].value === props.allOption.value) {
        setSingle(props.suggestions);
        return props.onChange(props.suggestions);
      } else if (value.length === props.suggestions.length) {
        result = value.filter(
          (option) => option.value !== props.allOption.value
        );
        setSingle(result);
        return props.onChange(result);
      }

      setSingle(value);
      return props.onChange(value);
    }

    setSingle(value);
    return props.onChange(value);
  };

  const customFilter = (option, inputValue) => {
    let a = option?.label.toString();
    let b = inputValue && inputValue.toString();
    if (searchWithStart) {
      if (b) {
        return a?.toLowerCase().startsWith(b.toLowerCase());
      } else return a.includes(b);
    } else {
      if (b) {
        return a?.toLowerCase().includes(b.toLowerCase());
      } else return a.includes(b);
    }
  };
  const idGenerator = (min, max) => {
    return min + Math.floor(Math.random() * (max - min + 1));
  }

  if (props.allowSelectAll) {
    return (
      <Styled.SelectWrapper>
        <Select
          {...props}
          defaultValue={''}
          isMulti={props.isMulti}
          removeSelected={false}
          name="colors"
          options={
            props.suggestions
              ? [{...props.allOption, label: i18n.t(props?.allOption?.label || "")}, ...props.suggestions]
              : props.suggestions
          }
          className="basic-multi-select"
          classNamePrefix="select"
          styles={colourStyles}
          placeholder={props.placeholder}
          onChange={handleChangeSingle}
          value={single}
          filterOption={(a, e) => customFilter(a, e)}
          id={props.id ? props.id : `select_menu_${idGenerator(5000, 1)}`}
          blurInputOnSelect={true}
        />
      </Styled.SelectWrapper>
    );
  } if (props?.multiSelect) {
    const selectedOptionsReArrange = (item, value) => {
        const stateOptions = props?.suggestions?.filter((option) => {
          return !item.find((newOpt) => {
            return newOpt === option;
          });
        }) ?? [];
        const updatedOptions = item?.concat(stateOptions);
        return updatedOptions;
    }

    const [options, setOptions] = useState(props?.suggestions);

    const MultiValueContainer = ({ getValue, index, ...rest }) => {
      if (index > 0) return null;
      const lists = getValue();
      // Truncate String
      // const string = truncate(join(map(lists, e => e?.label), ','), { length: 20, omission: `...+${lists?.length}`  });
      const string = `Event Types (${lists?.length} selected)`;
      return (
        <div style={{ whiteSpace: "nowrap" }}>
          {string}
        </div>
      );
    };

    const handleChange = (item, value) => {
      const updatedOptions = selectedOptionsReArrange(item, value);
      setOptions(updatedOptions);
      handleChangeSingle(item, value);
    }

    const CustomOption = (props) => {
      const { isSelected } = props;

      return (
        <components.Option {...props}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span style={{ marginRight: 10 }}>{props.data.label}</span>
            {isSelected ? <CheckBox
                          type="NormalWhite"
                          checked={true}
                          value={true}
                          tickColor={theme.colors.WHITE}
                          onClick={(event) => {}}
                          disabled={false}
                        /> : (<CheckBox
                          type="NormalWhite"
                          checked={false}
                          tickColor={theme.colors.WHITE}
                          onClick={(event) => {}}
                          disabled={false}
                        />)}
          </div>
        </components.Option>
      );
    };
    const modifyOptions = (list) => {
      const valueArray = props?.value || [];
      const valueSet = new Set(valueArray.map(v => v?.value));    
      const selected = list?.filter(e => valueSet.has(e?.value)) || [];
      const unSelected = list?.filter(e => !valueSet.has(e?.value)) || [];    
      return [...selected, ...unSelected];
    };
    return (
      <Styled.SelectWrapper className={props.className ? props.className : ''}>
        <Select
          {...props}
          defaultValue={[]}
          isMulti={props.isMulti}
          components={{ MultiValue: MultiValueContainer, Option: CustomOption }}
          name="colors"
          options={modifyOptions(options)}
          className="basic-multi-select"
          classNamePrefix="select"
          styles={colourStyles}
          hideSelectedOptions={false}
          isClearable={false}
          placeholder={props.placeholder}
          onChange={handleChangeSingle}
          isDisabled={props.disabled || false}
          closeMenuOnSelect={false}
          id={props.id ? props.id : `select_menu_${idGenerator(5000, 1)}`}
        />
      </Styled.SelectWrapper>
    );
  } else {
    return (
      <Styled.SelectWrapper className={props.className ? props.className : ''}>
        <Select
          {...props}
          defaultValue={''}
          isMulti={props.isMulti}
          name="colors"
          options={props.suggestions}
          className="basic-multi-select"
          classNamePrefix="select"
          styles={colourStyles}
          placeholder={props.placeholder}
          onChange={handleChangeSingle}
          isClearable={props.isClearable}
          isDisabled={props.disabled || false}
          filterOption={(a, e) => customFilter(a, e)}
          id={props.id ? props.id : `select_menu_${idGenerator(5000, 1)}`}
        />
      </Styled.SelectWrapper>
    );
  }
});

SelectFn.propTypes = {
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
};

SelectFn.defaultProps = {
  allOption: {
    label: 'Select All',
    value: '*'
  }
};

export default SelectFn;
