import moment from 'moment';
import PropTypes from 'prop-types';
import React, { lazy, useState } from 'react';
import { number, object, string } from 'yup';

import { get, isEqual } from 'lodash';
import { hasValue, objectArrayFromSameField } from '../../../utils/utils';
const layout = window.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`../../../components/UI/Form/${layout}`));
const Timezone = lazy(() => import(`@/components/modules/Timezone/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const ErrorIcon = lazy(() => import(`@/components/ErrorIcon/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const CompanyDataForm = ({
  apiStatus,
  history,
  Styled,
  i18n,
  innerComponent,
  companyData,
  states,
  webfleetProviders,
  saveCompanyData,
  callback,
  cancelSubscription,
  allCycleRules,
  allCycleExceptions,
  allVehicleTypes,
  updateCardDetails,
  updateCard,
}) => {
  const [AddEditModalObject, setAddEditModalObject] = useState({
    header: '',
    content: () => {
      return <> </>;
    }
  });
  const [addOpen, setAddOpen] = useState(false);
  const clickedAddEditModal = (e) => {
    setAddOpen(true);
  };
  const closeAddEditModal = (event) => {
    setAddOpen(false);
  };
  const apiId = 'saveCompanyData';
  //Check whether the api is calling
  const hasSaveApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const RenderComponent = lazy(() => import(`@/${innerComponent}`));
  const getTmezonePopup = (type, message, payload) => {
    payload = {
      ...payload,
      data: {
        ...payload.data,
        timeZone: companyData?.companyDto?.timeZoneId
      }
    };

    if (type === 'error') {
      setAddEditModalObject({
        header: '',
        content: () => {
          return (
            <div className="successpopup">
              <ErrorIcon />
              <h2> {i18n.t(message)} </h2>
              <div className="deletePopupBtn">
                <Button
                  type="submit"
                  label={i18n.t('common.ok')}
                  onClick={() => {
                    closeAddEditModal();
                  }}
                />
              </div>
            </div>
          );
        }
      });
      clickedAddEditModal();
    }
    if (message === 'Time zone not available') {
      setAddEditModalObject({
        header: '',
        content: () => {
          return (
            <Timezone
              closeModal={closeAddEditModal}
              callback={callback}
              payload={payload}
              saveCompanyData={saveCompanyData}
            />
          );
        }
      });
      clickedAddEditModal();
    }
  };
  return (
    <>
      <Form
        type="server"
        direction="column"
        initialValues={{
          subscriptionTier: companyData?.companyDto?.subscriptionTier || '',
          company: companyData?.companyDto?.company || '',
          dotNumber: companyData?.companyDto?.dotNumber || '',
          maintenancePeriod: companyData?.companyDto?.maintenancePeriod
            ? {
                label: companyData?.companyDto?.maintenancePeriod,
                value: companyData?.companyDto?.maintenancePeriod
              }
            : '',
          maintenanceMileage: companyData?.companyDto?.maintenanceMileage || '',
          address: companyData?.companyDto?.address || '',
          city: companyData?.companyDto?.city || '',
          state: companyData?.companyDto?.state
            ? {
                label: companyData?.companyDto?.state,
                value: companyData?.companyDto?.state
              }
            : '',
          webfleetProvider:  companyData?.companyDto?.webfleetProvider
          ? {
              label: companyData?.companyDto?.webfleetProvider,
              value: companyData?.companyDto?.webfleetProvider
            }
          : '',
          zipCode: companyData?.companyDto?.zipCode || '',
          email: companyData?.email || '',
          companyPhone: companyData?.companyDto?.companyPhone || '',
          initialTrucks: companyData?.companyDto?.initialTrucks || 0,
          timeZone: companyData?.companyDto?.timeZone || '',
          contactPerson: companyData?.companyDto?.contactPerson || '',
          otherLocationContactPerson:
            companyData?.companyDto?.otherLocationContactPerson || '',
          otherLocationAddress:
            companyData?.companyDto?.otherLocationAddress || '',
          otherLocationPhone: companyData?.companyDto?.otherLocationPhone || '',
          website: companyData?.companyDto?.website || '',
          skipVehicleFilter:
            companyData?.companyDto?.skipVehicleFilter || false,
          assignedVehicleType:
            companyData?.assignedVehicleType !== null
              ? {
                  label: companyData?.assignedVehicleType,
                  value: companyData?.assignedVehicleType
                }
              : allVehicleTypes[0],
          assignedCycleRules:
            companyData?.assignedCycleRules !== null
              ? companyData?.assignedCycleRules &&
                objectArrayFromSameField(companyData?.assignedCycleRules)
              : '',
          assignedCycleExceptions:
            companyData?.assignedCycleExceptions !== null
              ? companyData?.assignedCycleExceptions &&
                objectArrayFromSameField(companyData?.assignedCycleExceptions)
              : '',
          defaultCycleRule: companyData?.defaultCycleRule
            ? {
                label: companyData?.defaultCycleRule,
                value: companyData?.defaultCycleRule
              }
            : '',
          showOw: companyData?.companyDto?.showOw || false,
          contractExpirationDate: moment(
            companyData?.companyDto?.contractExpirationDate
          ).format('L'),
          currentSubscriptionCost: companyData?.companyDto
            ?.currentSubscriptionCost
            ? `$${(
                companyData?.companyDto?.currentSubscriptionCost / 100
              ).toFixed(2)}`
            : '$0.00',
            isIftaEnabled: companyData?.companyDto?.isIftaEnabled ,
            isDvirEnabled: companyData?.companyDto?.isDvirEnabled,
            clutchSubscriptionPlan: companyData?.companyDto?.clutchSubscriptionPlan,
            alertsToEmail: companyData?.companyDto?.alertsToEmail?.length ? companyData?.companyDto?.alertsToEmail?.map(e => ({ label: e, value: e })) : [],
            defaultAlertsToEmail: companyData?.companyDto?.alertsToEmail?.length ? companyData?.companyDto?.alertsToEmail?.map(e => ({ label: e, value: e })) : []
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          fields.otherLocationPhone = fields?.otherLocationPhone.length ? fields.otherLocationPhone : '';
          fields.state = fields.state.value;
          fields.webfleetProvider = fields?.webfleetProvider?.value;
          fields.maintenancePeriod = fields?.maintenancePeriod?.value;
          fields.assignedVehicleType =
            fields?.assignedVehicleType?.value || null;
          fields.defaultCycleRule = fields?.defaultCycleRule?.value || null;
          fields.assignedCycleExceptions = fields?.assignedCycleExceptions
            ? fields.assignedCycleExceptions.map((key) => {
                return key.value;
              })
            : null;
          fields.assignedCycleRules = fields?.assignedCycleRules
            ? fields.assignedCycleRules.map((key) => {
                return key.value;
              })
            : null;
          // fields.alertsToEmail = fields?.alertsToEmail?.length > 0 ? fields?.alertsToEmail?.map(e => e?.value) : null;
          if (window.$environment?.SERVICE_PROVIDER === 'riskTheory') {
            fields.alertsToEmail = isEqual(fields?.alertsToEmail, fields?.defaultAlertsToEmail) ? null
            : (!get(fields, 'alertsToEmail') && get(fields, 'defaultAlertsToEmail.length') > 0) ? [] : fields?.alertsToEmail?.map(e => e?.value);
          } else {
            delete fields['alertsToEmail'];
          }
          delete fields['timeZone'];
          delete fields['defaultAlertsToEmail'];
          delete fields['contractExpirationDate'];
          delete fields['currentSubscriptionCost'];
          saveCompanyData({
            apiId,
            data: fields,
            callback: getTmezonePopup
          });
        }}
        customProps={{
          hasSaveApiStatus,
          history,
          Styled,
          i18n,
          states,
          webfleetProviders,
          cancelSubscription,
          apiStatus,
          callback,
          allCycleRules,
          allCycleExceptions,
          allVehicleTypes,
          updateCard,
          updateCardDetails,
        }}
        validationSchema={object().shape({
          company: string()
            .required(i18n.t('common.fieldRequiredMessage'))
            .test(
              'len',
              i18n.t('Please enter atleast 4 characters.'),
              (val) => val && val.length > 3
            )
            .test(
              'len',
              i18n.t('Please enter no more than 120 characters.'),
              (val) => val && val.length < 121
            ),
          dotNumber: number()
            .typeError('Please use numbers with maximum length 9')
            .required(i18n.t('common.fieldRequiredMessage')),
          maintenanceMileage: string().matches(
            /^\d*(\.\d+)?$/,
            i18n.t('common.validNumberMessage')
          ),
          address: string().required(i18n.t('common.fieldRequiredMessage')),
          city: string().required(i18n.t('common.fieldRequiredMessage')),
          state: string().required(i18n.t('common.fieldRequiredMessage')),
          zipCode: string().required(i18n.t('common.fieldRequiredMessage')),
          companyPhone: string().required(i18n.t('common.fieldRequiredMessage'))
        })}
      />
      <Modal
        open={addOpen}
        setOpen={setAddOpen}
        data={AddEditModalObject}
        background="black"
        timeZone
        isSuccess
      />
    </>
  );
};
export default CompanyDataForm;

CompanyDataForm.defaultType = {
  hasRegistration: true
};
CompanyDataForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  fetchLogin: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  Styled: PropTypes.object.isRequired,
  i18n: PropTypes.array.isRequired,
  hasRegistration: PropTypes.bool
};
